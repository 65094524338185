export const isEmpty = (value) => {
  return value == null || value.length === 0;
};

export const isValidEmail = (value) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const isValidPassword = (value) => {
  var re =
    /^(?!(.*\[^A-Za-z0-9]){1,})(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,15}$/;
  return re.test(String(value));
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatTime = (date, omitSeconds = false) => {
  let t = new Date(date);

  let hours = ("0" + t.getHours()).slice(-2);
  let minutes = ("0" + t.getMinutes()).slice(-2);
  let seconds = omitSeconds ? 0 : ("0" + t.getSeconds()).slice(-2);

  return [hours, minutes, seconds].join(":");
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isUrlImage = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const isURL = (url) => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

export const getFileType = (file) => {
  if (file.type.match("image.*")) return "image";

  if (file.type.match("video.*")) return "video";

  if (file.type.match("audio.*")) return "audio";

  if (file.type.match("application/pdf")) return "file";

  return "other";
};

export const hasAuthority = (user, permissions) => {
  // Checking if user or permissions exist
  if (!user || !permissions) {
    return false;
  }

  // Looping through allowed roles
  for (let role of permissions) {
    if (role.includes("*")) {
      if (user.role === role.replace("*", "") && user.isSuper) {
        return true;
      }
    }

    // Default comparison
    if (user.role === role) {
      return true;
    }
  }

  // Default return
  return false;
};

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
