const initialState = {};

export const userReducer = (state = initialState, action) => {
  const platform = localStorage.getItem("platform");

  const CRMRoles = {
    0: "developer",
    1: "admin",
    2: "manager",
    3: "promoter",
    4: "teacher",
    5: "reception",
    6: "assistant",
  };

  const AppRoles = {
    1: "admin",
    2: "manager",
    3: "teacher",
  };

  switch (action.type) {
    case "LOGIN": {
      if (platform === "crm") {
        action.user.role = CRMRoles[action.user.role];
      } else if (platform === "app") {
        action.user.role = AppRoles[action.user.role];
      }

      return { ...state, ...action.user };
    }
    case "EDIT": {
      return { ...state, ...action.user };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT": {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
