import { combineReducers } from "redux"
import customizer from "./customizer/"
import user from "./user/"
import navbar from "./navbar/"

const rootReducer = combineReducers({
  customizer: customizer,
  user: user,
  navbar: navbar
})

export default rootReducer
