import React from "react";
import * as Icon from "react-feather";

export const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    //permissions: ["developer", "admin", "editor"],
    navLink: "/",
  },
];
