import { history } from "../../../history";

export const login = (user) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      user: user,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("platform");
    localStorage.removeItem("token");

    localStorage.removeItem("facebook_token");
    localStorage.removeItem("facebook_token_exp");

    dispatch({
      type: "LOGOUT",
    });

    history.push("/login");
  };
};

export const edit = (user) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT",
      user: user,
    });
  };
};
