import React from "react";
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import {
  CheckSquare,
  Coffee,
  CreditCard,
  Feather,
  List,
  Menu,
  RefreshCw,
  Star,
  UserPlus,
  Users,
} from "react-feather";

import { history } from "../../../history";

class NavbarBookmarks extends React.PureComponent {
  render() {
    let { sidebarVisibility, role } = this.props;

    const platform = localStorage.getItem("platform");

    let bookmarks;

    if (platform === "crm") {
      bookmarks = {
        developer: [
          {
            id: "customers",
            name: "Customers",
            link: "/customers",
            icon: <Users size={20} />,
          },
          {
            id: "cycles",
            name: "Cycles",
            link: "/cycles",
            icon: <RefreshCw size={20} />,
          },
          {
            id: "payments",
            name: "Payments",
            link: "/payments",
            icon: <CreditCard size={20} />,
          },
          {
            id: "potentials",
            name: "Potentials",
            link: "/potentials",
            icon: <UserPlus size={20} />,
          },
        ],
        admin: [
          {
            id: "customers",
            name: "Customers",
            link: "/customers",
            icon: <Users size={20} />,
          },
          {
            id: "cycles",
            name: "Cycles",
            link: "/cycles",
            icon: <RefreshCw size={20} />,
          },
          {
            id: "payments",
            name: "Payments",
            link: "/payments",
            icon: <CreditCard size={20} />,
          },
          {
            id: "potentials",
            name: "Potentials",
            link: "/potentials",
            icon: <UserPlus size={20} />,
          },
        ],
        manager: [
          {
            id: "customers",
            name: "Customers",
            link: "/customers",
            icon: <Users size={20} />,
          },
          {
            id: "meetings",
            name: "Meetings",
            link: "/meetings",
            icon: <Coffee size={20} />,
          },
          {
            id: "payments",
            name: "Payments",
            link: "/payments",
            icon: <CreditCard size={20} />,
          },
          {
            id: "potentials",
            name: "Potentials",
            link: "/potentials",
            icon: <UserPlus size={20} />,
          },
        ],
        promoter: [
          {
            id: "customers",
            name: "Customers",
            link: "/customers",
            icon: <Users size={20} />,
          },
          {
            id: "meetings",
            name: "Meetings",
            link: "/meetings",
            icon: <Coffee size={20} />,
          },
          {
            id: "payments",
            name: "Payments",
            link: "/payments",
            icon: <CreditCard size={20} />,
          },
          {
            id: "potentials",
            name: "Potentials",
            link: "/potentials",
            icon: <UserPlus size={20} />,
          },
        ],
        teacher: [
          {
            id: "checkings",
            name: "Checkings",
            link: "/checkings",
            icon: <CheckSquare size={20} />,
          },
          /*{
                        id: 'courses',
                        name: 'Courses',
                        link: '/courses',
                        icon: <BookOpen size={20} />
                    },*/
          {
            id: "cycles",
            name: "Cycles",
            link: "/cycles",
            icon: <RefreshCw size={20} />,
          },
        ],
        reception: [
          {
            id: "cycles",
            name: "Cycle List",
            link: "/cycles",
            icon: <RefreshCw size={20} />,
          },
          {
            id: "meetings",
            name: "Meetings",
            link: "/meetings",
            icon: <Coffee size={20} />,
          },
          {
            id: "students",
            name: "Students",
            link: "/students",
            icon: <Users size={20} />,
          },
        ],
        assistant: [
          {
            id: "customers",
            name: "Customers",
            link: "/customers",
            icon: <Users size={20} />,
          },
          {
            id: "cycles",
            name: "Cycles",
            link: "/cycles",
            icon: <RefreshCw size={20} />,
          },
        ],
      };
    } else if (platform === "app") {
      bookmarks = {
        admin: [
          {
            id: "courses",
            name: "Courses",
            link: "/courses",
            icon: <Feather size={20} />,
          },
          {
            id: "reviews",
            name: "Reviews",
            link: "/reviews",
            icon: <Star size={20} />,
          },
          {
            id: "users",
            name: "Users",
            link: "/users",
            icon: <Users size={20} />,
          },
        ],
        manager: [
          {
            id: "courses",
            name: "Courses",
            link: "/courses",
            icon: <Feather size={20} />,
          },
          {
            id: "reviews",
            name: "Reviews",
            link: "/reviews",
            icon: <Star size={20} />,
          },
          {
            id: "users",
            name: "Users",
            link: "/users",
            icon: <Users size={20} />,
          },
        ],
        teacher: [
          {
            id: "courses",
            name: "Courses",
            link: "/courses",
            icon: <Feather size={20} />,
          },
        ],
      };
    }

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>

        <ul className="nav navbar-nav bookmark-icons">
          {bookmarks[role].map((bookmark) => (
            <NavItem className="nav-item d-none d-lg-block" key={bookmark.id}>
              <NavLink
                tag="span"
                id={bookmark.id}
                className="nav-link cursor-pointer"
                onClick={() => history.push(bookmark.link)}
              >
                {bookmark.icon}
              </NavLink>

              <UncontrolledTooltip placement="bottom" target={bookmark.id}>
                {bookmark.name}
              </UncontrolledTooltip>
            </NavItem>
          ))}
        </ul>
      </div>
    );
  }
}

export default NavbarBookmarks;
