import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import axios from "axios";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";

const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>
);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
